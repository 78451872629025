import PropTypes from "prop-types";
import React, { useMemo } from "react";

export default function SidebarUi(props) {
  const { visible, children } = props;

  const classes = useMemo(
    () => ["SidebarUi", visible ? "is-visible" : ""].join(" "),
    [visible]
  );

  return <div className={classes}>{children}</div>;
}

SidebarUi.propTypes = {
  visible: PropTypes.bool,
};
SidebarUi.defaultProps = {
  visible: true,
};
