import React, { useMemo } from "react";
import PropTypes from "prop-types";

export default function ToggleUi(props) {
  const { onClick, visible } = props;
  const classes = useMemo(
    () => ["ToggleUi", visible ? "is-hidden" : ""].join(" "),
    [visible]
  );
  return (
    <button className={classes} onClick={onClick}>
      <span>
        {visible ? (
          "Chiudi"
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 19.846">
            <g data-name="Raggruppa 513" transform="translate(-5950 5838)">
              <path
                data-name="Unione 1"
                d="M5978-5820.654h-28v-1h28Zm0-7h-28v-1h28Zm0-7h-28v-1h28Z"
                fill="#222221"
              />
              <path
                data-name="Tracciato 195"
                d="M5969.715-5838a3 3 0 1 1-3 3 3 3 0 0 1 3-3Z"
                fill="#fbf9f6"
              />
              <path
                data-name="Tracciato 195 - Contorno"
                d="M5969.715-5837a2 2 0 1 0 2 2 2 2 0 0 0-2-2m0-1a3 3 0 1 1-3 3 3 3 0 0 1 3-3Z"
                fill="#222221"
              />
              <circle
                data-name="Ellisse 35"
                cx="3"
                cy="3"
                r="3"
                transform="translate(5954 -5831.154)"
                fill="#fbf9f6"
              />
              <path
                data-name="Ellisse 35 - Contorno"
                d="M5957-5830.154a2 2 0 1 0 2 2 2 2 0 0 0-2-2m0-1a3 3 0 1 1-3 3 3 3 0 0 1 3-3Z"
                fill="#222221"
              />
              <circle
                data-name="Ellisse 36"
                cx="3"
                cy="3"
                r="3"
                transform="translate(5966 -5824.154)"
                fill="#fbf9f6"
              />
              <path
                data-name="Ellisse 36 - Contorno"
                d="M5969-5823.154a2 2 0 1 0 2 2 2 2 0 0 0-2-2m0-1a3 3 0 1 1-3 3 3 3 0 0 1 3-3Z"
                fill="#222221"
              />
            </g>
          </svg>
        )}
      </span>
    </button>
  );
}

ToggleUi.propTypes = {
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};
ToggleUi.defaultProps = {
  visible: false,
};
