import "@hotwired/turbo-rails";
import "./IndexFilters";
import "./Configurator";
import "./aframe/model-viewer";
import "./aframe/aframe-extras";
import "./aframe/background-gradient";
import "./aframe/ar-shadows";

/**
 * Gestione lingue
 */
document.addEventListener("DOMContentLoaded", () => {
  const localesMeta = document.querySelector('meta[name="js-locale"]')
  if (localesMeta) {
    const localesJson = JSON.parse(localesMeta.getAttribute('content'))
    window.jsLocale = (key) => localesJson[key] || `translation missing "${key}"`
  } else {
    window.jsLocale = (key) => ''
  }

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
})

/**
 * Gestione wheel
 */
window.addEventListener(
  "wheel",
  (e) => {
    if (e.ctrlKey) {
      e.preventDefault();
    }
  },
  {
    passive: false,
  }
);

/**
 * Gestione index filtri ricerca
 */

const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".js-index-filters-autosubmit").forEach((input) => {
    input.addEventListener(
      input.dataset.event || "change",
      debounce(function () {
        document.querySelector(".js-index-filters-submit").click();
      }, 250)
    );
  });
});
