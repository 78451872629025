if (typeof AFRAME === "undefined") return;

/* global AFRAME, THREE */
AFRAME.registerComponent("model-viewer", {
  schema: {
    gltfModelBase: { default: "" },
    textureSedile: { default: "" },
    textureSchienale: { default: "" },
    textureBase: { default: "" },
    textureBaseGas: { default: "" },
    coloreSedile: { default: "" },
    coloreSchienale: { default: "" },
    coloreBase: { default: "" },
    trapuntatura: { default: "" },
    traslazioneY: { default: 0 },
    traslazioneZ: { default: 0 },
    rotazioneX: { default: 0 },
  },

  init() {
    this.el.setAttribute("renderer", { colorManagement: true });
    this.el.setAttribute("cursor", { rayOrigin: "mouse", fuse: false });
    this.el.setAttribute("webxr", {
      optionalFeatures: "hit-test, local-floor",
    });
    this.el.setAttribute("raycaster", { objects: ".raycastable" });

    this.initCameraRig();
    this.initEntities();
    this.initBackground();

    // Disable context menu on canvas when pressing mouse right button;
    this.el.sceneEl.canvas.oncontextmenu = function (evt) {
      evt.preventDefault();
    };

    window.addEventListener("orientationchange", this.onOrientationChange);

    // VR controls.
    this.laserHitPanelEl.addEventListener("mousedown", (e) =>
      this.onMouseDownLaserHitPanel(e)
    );
    this.laserHitPanelEl.addEventListener("mouseup", (e) =>
      this.onMouseUpLaserHitPanel(e)
    );

    this.leftHandEl.addEventListener("thumbstickmoved", (e) =>
      this.onThumbstickMoved(e)
    );
    this.rightHandEl.addEventListener("thumbstickmoved", (e) =>
      this.onThumbstickMoved(e)
    );

    // Mouse 2D controls.
    this.el.addEventListener("mouseup", (e) => this.onMouseUp(e));
    this.el.addEventListener("mousemove", (e) => this.onMouseMove(e));
    this.el.addEventListener("mousedown", (e) => this.onMouseDown(e));
    this.el.addEventListener("wheel", (e) => this.onMouseWheel(e));

    // Mobile 2D controls.
    this.el.addEventListener("touchend", (e) => this.onTouchEnd(e));
    this.el.addEventListener("touchmove", (e) => this.onTouchMove(e));

    this.el.sceneEl.addEventListener("enter-vr", (e) => this.onEnterVR(e));
    this.el.sceneEl.addEventListener("exit-vr", (e) => this.onExitVR(e));

    this.modelEl.addEventListener("model-loaded", (e) => this.onModelLoaded(e));

    // Variable to manage load status from configurator data
    this.setup = false;
    this.previousData = {};
    this.sedutaHasTexture = false;
  },

  update() {
    if (!this.data.gltfModelBase) return;
    if (!this.setup) this.runSetup();

    this.runUpdate();
  },

  tick() {
    var intersection;
    var intersectionPosition;
    if (!this.el.sceneEl.is("vr-mode")) {
      return;
    }
    if (!this.activeHandEl) {
      return;
    }
    intersection = this.activeHandEl.components.raycaster.getIntersection(
      this.laserHitPanelEl
    );
    if (!intersection) {
      this.activeHandEl.setAttribute("raycaster", "lineColor", "white");
      return;
    }
    this.activeHandEl.setAttribute("raycaster", "lineColor", "#007AFF");
    intersectionPosition = intersection.point;
    this.oldHandX = this.oldHandX || intersectionPosition.x;
    this.oldHandY = this.oldHandY || intersectionPosition.y;

    this.modelPivotEl.object3D.rotation.y -=
      (this.oldHandX - intersectionPosition.x) / 4;
    this.modelPivotEl.object3D.rotation.x +=
      (this.oldHandY - intersectionPosition.y) / 4;

    this.oldHandX = intersectionPosition.x;
    this.oldHandY = intersectionPosition.y;
  },

  /**
   * HELPERS
   */

  initCameraRig() {
    this.cameraRigEl = document.createElement("a-entity");
    this.cameraEl = document.createElement("a-entity");
    this.rightHandEl = document.createElement("a-entity");
    this.leftHandEl = document.createElement("a-entity");

    this.cameraEl.setAttribute("camera", { fov: 60 });
    this.cameraEl.setAttribute("look-controls", {
      magicWindowTrackingEnabled: false,
      mouseEnabled: false,
      touchEnabled: false,
    });

    this.rightHandEl.setAttribute("rotation", "0 90 0");
    this.rightHandEl.setAttribute("laser-controls", { hand: "right" });
    this.rightHandEl.setAttribute("raycaster", { objects: ".raycastable" });
    this.rightHandEl.setAttribute("line", { color: "#118A7E" });

    this.leftHandEl.setAttribute("rotation", "0 90 0");
    this.leftHandEl.setAttribute("laser-controls", { hand: "right" });
    this.leftHandEl.setAttribute("raycaster", { objects: ".raycastable" });
    this.leftHandEl.setAttribute("line", { color: "#118A7E" });

    this.cameraRigEl.appendChild(this.cameraEl);
    this.cameraRigEl.appendChild(this.rightHandEl);
    this.cameraRigEl.appendChild(this.leftHandEl);

    this.el.appendChild(this.cameraRigEl);
  },

  initBackground() {
    var backgroundEl = (this.backgroundEl = document.querySelector("a-entity"));
    backgroundEl.setAttribute("geometry", { primitive: "sphere", radius: 65 });
    backgroundEl.setAttribute("material", {
      shader: "background-gradient",
      colorTop: "#ffffff",
      colorBottom: "#eeeeee",
      side: "back",
    });
    backgroundEl.setAttribute("hide-on-enter-ar", "");
  },

  hideBackground() {
    this.backgroundEl.setAttribute("visible", false);
  },

  showBackground() {
    this.backgroundEl.setAttribute("visible", true);
  },

  initEntities() {
    // Container for our entities to keep the scene clean and tidy.
    this.containerEl = document.createElement("a-entity");
    // Plane used as a hit target for laser controls when in VR mode
    this.laserHitPanelEl = document.createElement("a-entity");
    // Models are often not centered on the 0,0,0.
    // We will center the model and rotate a pivot.
    this.modelPivotEl = document.createElement("a-entity");
    // This is our glTF model entity.
    this.modelEl = document.createElement("a-entity");
    // Real time shadow only used in AR mode.
    this.arShadowEl = document.createElement("a-entity");
    // Reticle model used to position the model in AR mode.
    this.reticleEl = document.createElement("a-entity");
    // Scene ligthing.
    this.lightEl = document.createElement("a-entity");
    this.sceneLightEl = document.createElement("a-entity");

    this.sceneLightEl.setAttribute("light", {
      type: "ambient",
      intensity: 0.5,
      color: "#ffffff",
    });

    this.modelPivotEl.id = "modelPivot";

    this.el.appendChild(this.sceneLightEl);

    this.reticleEl.setAttribute("gltf-model", "#reticle");
    this.reticleEl.setAttribute("scale", "0.8 0.8 0.8");
    // BACKUP: Causa errori in console. Per ora disattivata.
    // this.reticleEl.setAttribute('ar-hit-test', { targetEl: '#modelPivot' });
    this.reticleEl.setAttribute("visible", "false");

    this.modelEl.id = "model";

    this.laserHitPanelEl.id = "laserHitPanel";
    this.laserHitPanelEl.setAttribute("position", "0 0 -10");
    this.laserHitPanelEl.setAttribute(
      "geometry",
      "primitive: plane; width: 30; height: 20"
    );
    this.laserHitPanelEl.setAttribute("material", "color: red");
    this.laserHitPanelEl.setAttribute("visible", "false");
    this.laserHitPanelEl.classList.add("raycastable");

    this.containerEl.appendChild(this.laserHitPanelEl);

    this.modelEl.setAttribute("rotation", "0 -30 0");
    this.modelEl.setAttribute("animation-mixer", "");
    this.modelEl.setAttribute("shadow", "cast: true; receive: false");

    // FOR DEBUG ONLY
    // this.modelEl.setAttribute('geometry', 'primitive: box')
    // this.modelEl.setAttribute('material', 'color: #4CC3D9; opacity: 0.35')
    // --------------

    this.modelPivotEl.appendChild(this.modelEl);

    this.arShadowEl.setAttribute("rotation", "-90 0 0");
    this.arShadowEl.setAttribute(
      "geometry",
      "primitive: plane; width: 30.0; height: 30.0"
    );
    this.arShadowEl.setAttribute("shadow", "recieve: true");
    this.arShadowEl.setAttribute("ar-shadows", "opacity: 0.2");
    this.arShadowEl.setAttribute("visible", "false");

    this.modelPivotEl.appendChild(this.arShadowEl);

    this.lightEl.id = "light";
    this.lightEl.setAttribute("position", "-2 0 2");
    this.lightEl.setAttribute("light", {
      type: "directional",
      intensity: 0.5,
      color: "#ffffff",
      // castShadow: true
    });

    this.containerEl.appendChild(this.lightEl);
    this.containerEl.appendChild(this.modelPivotEl);

    this.el.appendChild(this.containerEl);
    this.el.appendChild(this.reticleEl);
  },

  runSetup() {
    // creo seduta
    this.modelSedutaEl = document.createElement("a-entity");
    this.modelSedutaEl.setAttribute("gltf-model", "#seduta");
    this.modelEl.appendChild(this.modelSedutaEl);

    // creo base
    this.modelBaseEl = document.createElement("a-entity");
    this.modelEl.appendChild(this.modelBaseEl);

    // update setup
    this.setup = true;
  },

  runUpdate() {
    console.log("🧊 model-viewer.runUpdate", this.data);

    // aggiorno traslazione seduta
    if (
      this.data.traslazioneY != this.previousData.traslazioneY ||
      this.data.traslazioneZ != this.previousData.traslazioneZ
    ) {
      this.modelSedutaEl.setAttribute(
        "position",
        `0 ${this.data.traslazioneY} ${this.data.traslazioneZ}`
      );
    }

    // aggiorno rotazione seduta
    if (this.data.rotazioneX != this.previousData.rotazioneX) {
      this.modelSedutaEl.setAttribute(
        "rotation",
        `${this.data.rotazioneX} 0 0`
      );
    }

    // aggiorno modello base
    if (this.data.gltfModelBase != this.previousData.gltfModelBase) {
      this.modelBaseEl.removeAttribute("gltf-model");
      this.modelBaseEl.setAttribute("gltf-model", this.data.gltfModelBase);
      this.modelBaseEl.setAttribute("position", "0 0 0");
    }

    // aggiorno texture seduta
    if (
      this.data.textureSedile &&
      (this.data.textureSedile != this.previousData.textureSedile ||
        this.data.coloreSedile != this.previousData.coloreSedile)
    ) {
      const textureSedileJson = JSON.parse(this.data.textureSedile);
      const colorSedile = this.data.coloreSedile || "#ffffff";
      this.applyColorAndTexture(
        colorSedile,
        textureSedileJson,
        this.modelSedutaEl,
        false,
        ["!_back_", "!_supporto"]
      );
      this.sedutaHasTexture = true;
    } else if (!this.data.textureSedile) {
      // reset
      this.resetTextureSeduta();
    }

    // aggiorno texture base
    if (
      this.data.textureBase &&
      (this.data.textureBase != this.previousData.textureBase ||
        this.data.coloreBase != this.previousData.coloreBase)
    ) {
      const textureBaseJson = JSON.parse(this.data.textureBase);
      const colorBase = this.data.coloreBase || "#ffffff";
      this.applyColorAndTexture(colorBase, textureBaseJson, this.modelBaseEl);
      this.applyColorAndTexture(
        colorBase,
        textureBaseJson,
        this.modelSedutaEl,
        false,
        "_supporto"
      );
    } else if (!this.data.textureBase) {
      // reset
      this.resetTextureBase();
    }

    // aggiorno texture base gas
    if (
      this.data.textureBaseGas &&
      (this.data.textureBaseGas != this.previousData.textureBaseGas ||
        this.data.coloreBase != this.previousData.coloreBase)
    ) {
      const textureBaseGasJson = JSON.parse(this.data.textureBaseGas);
      const colorBase = this.data.coloreBase || "#ffffff";
      this.applyColorAndTexture(
        colorBase,
        textureBaseGasJson,
        this.modelBaseEl,
        false,
        "_pistone"
      );
    }

    // aggiorno texture schienale
    if (
      this.data.textureSchienale &&
      (this.data.textureSchienale != this.previousData.textureSchienale ||
        this.data.coloreSchienale != this.previousData.coloreSchienale ||
        this.data.trapuntatura != this.previousData.trapuntatura)
    ) {
      const textureSchienaleJson = JSON.parse(this.data.textureSchienale);
      const colorSchienale = this.data.coloreSchienale || "#ffffff";
      this.applyColorAndTexture(
        colorSchienale,
        textureSchienaleJson,
        this.modelSedutaEl,
        this.data.trapuntatura,
        "_back_"
      );
    }

    this.previousData = { ...this.data };
  },

  dragModel(evt) {
    var dX;
    var dY;
    var modelPivotEl = this.modelPivotEl;
    if (!this.oldClientX) {
      return;
    }
    dX = this.oldClientX - evt.clientX;
    dY = this.oldClientY - evt.clientY;
    modelPivotEl.object3D.position.y += dY / 200;
    modelPivotEl.object3D.position.x -= dX / 200;
    this.oldClientX = evt.clientX;
    this.oldClientY = evt.clientY;
  },

  rotateModel(evt) {
    var dX;
    var dY;
    var modelPivotEl = this.modelPivotEl;
    if (!this.oldClientX) {
      return;
    }
    dX = this.oldClientX - evt.clientX;
    dY = this.oldClientY - evt.clientY;
    modelPivotEl.object3D.rotation.y -= dX / 100;
    modelPivotEl.object3D.rotation.x -= dY / 200;

    // Clamp x rotation to [-90,90]
    modelPivotEl.object3D.rotation.x = Math.min(
      Math.max(-Math.PI / 2, modelPivotEl.object3D.rotation.x),
      Math.PI / 2
    );

    this.oldClientX = evt.clientX;
    this.oldClientY = evt.clientY;
  },

  centerAndScaleModel() {
    if (!this._centerAndScaleModel) this._centerAndScaleModel = 0;
    if (this._centerAndScaleModel >= 2) return;
    var box;
    var size;
    var center;
    var scale;
    var gltfObject = this.modelEl.object3D;

    // Reset position and scales.
    this.modelEl.object3D.position.set(0, 0, 0);
    this.modelEl.object3D.scale.set(1.0, 1.0, 1.0);
    this.cameraRigEl.object3D.position.z = 3.0;

    // Calculate model size.
    this.modelEl.object3D.updateMatrixWorld();
    box = new THREE.Box3().setFromObject(gltfObject);
    size = box.getSize(new THREE.Vector3());

    // Calculate scale factor to resize model to human scale.
    scale = 1.6 / size.y;
    scale = 1.0 / size.x < scale ? 1.0 / size.x : scale;
    scale = 1.0 / size.z < scale ? 1.0 / size.z : scale;

    this.modelEl.object3D.scale.set(scale, scale, scale);

    // Center model at (0, 0, 0).
    this.modelEl.object3D.updateMatrixWorld();
    box = new THREE.Box3().setFromObject(gltfObject);
    center = box.getCenter(new THREE.Vector3());
    size = box.getSize(new THREE.Vector3());

    this.modelEl.object3D.position.x = -center.x;
    this.modelEl.object3D.position.y = -center.y - 0.1; // NOTE: sommare/togliere per cambiare altezza di default sedia
    this.modelEl.object3D.position.z = -center.z;

    // When in mobile landscape we want to bring the model a bit closer.
    if (AFRAME.utils.device.isLandscape()) {
      this.cameraRigEl.object3D.position.z -= 1;
    }

    this._centerAndScaleModel += 1; // !!this.modelBaseEl && !!this.modelSedutaEl;
  },

  applyColorAndTexture(
    color,
    textureJson,
    model,
    applyTrapuntatura = false,
    mashPattern = null
  ) {
    const typology = textureJson?.typology;

    const mapTexture = textureJson?.color
      ? new THREE.TextureLoader().load(textureJson.color, (texture) => {
          texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          texture.offset.set(0, 0);
          texture.repeat.set(textureJson.repetition, textureJson.repetition);
        })
      : null;

    const normalMapTexture = textureJson?.normal
      ? new THREE.TextureLoader().load(textureJson.normal, (texture) => {
          texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          texture.offset.set(0, 0);
          texture.repeat.set(textureJson.repetition, textureJson.repetition);
        })
      : null;

    const roughnessMapTexture = textureJson?.roughness
      ? new THREE.TextureLoader().load(textureJson.roughness, (texture) => {
          texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          texture.offset.set(0, 0);
          texture.repeat.set(textureJson.repetition, textureJson.repetition);
        })
      : null;

    const metalnessMapTexture = textureJson?.metal
      ? new THREE.TextureLoader().load(textureJson.metal, (texture) => {
          texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          texture.offset.set(0, 0);
          texture.repeat.set(textureJson.repetition, textureJson.repetition);
        })
      : null;

    const envMapTexture = new THREE.TextureLoader().load(
      "/configurator/studio_small_08_1k.png",
      (texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        texture.encoding = THREE.sRGBEncoding;
      }
    );

    const normalMapTrapuntaturaTexture = textureJson?.trapuntatura
      ? new THREE.TextureLoader().load(textureJson?.trapuntatura, (texture) => {
          texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          texture.offset.set(0, 0);
          texture.repeat.set(8, 8);
        })
      : null;

    const aoMapTexture = textureJson?.trapuntatura_aomaps
      ? new THREE.TextureLoader().load(
          this.data.trapuntatura_aomaps,
          (texture) => {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(8, 8);
          }
        )
      : null;

    // preparo materiale
    const material = new THREE.MeshStandardMaterial({
      color: color,
      map: mapTexture,
      normalMap: applyTrapuntatura
        ? normalMapTrapuntaturaTexture
        : normalMapTexture,
      roughnessMap: roughnessMapTexture,
      metalnessMap: metalnessMapTexture,
      aoMap: applyTrapuntatura ? aoMapTexture : null,
      envMap: ["metal"].includes(typology) ? envMapTexture : null,
      metalness: ["metal"].includes(typology)
        ? 1
        : ["coated"].includes(typology)
        ? 0.5
        : undefined,
      roughness: ["wood", "metal"].includes(typology)
        ? 1
        : ["coated"].includes(typology)
        ? 0.5
        : undefined,
      fog: false,
    });

    // preparo materiale di default
    const defaultMaterial = new THREE.MeshStandardMaterial({
      color: "#8a8c97",
    });

    // applico materiale al modello
    model.getObject3D("mesh")?.traverse((node) => {
      if (node.isMesh) {
        // esclusione custom di mashPattern
        if (mashPattern) {
          if (typeof mashPattern == "object") {
            for (let i = 0; i < mashPattern.length; i++) {
              if (mashPattern[i].startsWith("!")) {
                // applica se diverso da
                const notMashPattern = mashPattern[i].replace("!", "");
                if (node.name.toLowerCase().includes(notMashPattern)) return;
              } else {
                // applica se uguale a
                if (!node.name.toLowerCase().includes(mashPattern[i])) return;
              }
            }
          } else {
            if (mashPattern.startsWith("!")) {
              // applica se diverso da
              const notMashPattern = mashPattern.replace("!", "");
              if (node.name.toLowerCase().includes(notMashPattern)) return;
            } else {
              // applica se uguale a
              if (!node.name.toLowerCase().includes(mashPattern)) return;
            }
          }
        }

        // escludo i dettagli di default
        if (node.name.toLowerCase().includes("_dett")) {
          node.material = defaultMaterial;
          return;
        }

        // applico trapuntatura di default
        if (node.name.toLowerCase().includes("_trapunta")) {
          const clonedMaterial = material.clone();
          clonedMaterial.normalMap = normalMapTrapuntaturaTexture;
          clonedMaterial.aoMap = aoMapTexture;
          node.material = clonedMaterial;
          return;
        }

        // applicazione materiale
        node.material = material;

        // aggiunta regole specifiche per envMap
        if (node.material.envMap) {
          node.material.envMap.intensity = 2;
          node.material.needsUpdate = true;
        }
      }
    });
  },

  resetTextureSeduta() {
    this.applyColorAndTexture(
      "#fff",
      null,
      this.modelSedutaEl,
      false,
      "!_supporto"
    );
  },

  resetTextureBase() {
    this.applyColorAndTexture("#fff", null, this.modelBaseEl);
    this.applyColorAndTexture(
      "#fff",
      null,
      this.modelSedutaEl,
      false,
      "_supporto"
    );
  },

  /**
   * EVENTS
   */

  onThumbstickMoved(evt) {
    var modelPivotEl = this.modelPivotEl;
    var modelScale = this.modelScale || modelPivotEl.object3D.scale.x;
    modelScale -= evt.detail.y / 20;
    modelScale = Math.min(Math.max(1.0, modelScale), 2.0);
    modelPivotEl.object3D.scale.set(modelScale, modelScale, modelScale);
    this.modelScale = modelScale;
  },

  onMouseWheel(evt) {
    var modelPivotEl = this.modelPivotEl;
    var modelScale = this.modelScale || modelPivotEl.object3D.scale.x;
    modelScale -= evt.deltaY / 500;
    modelScale = Math.min(Math.max(1.0, modelScale), 4.5);
    // Clamp scale.
    modelPivotEl.object3D.scale.set(modelScale, modelScale, modelScale);
    this.modelScale = modelScale;
  },

  onMouseDownLaserHitPanel(evt) {
    var cursorEl = evt.detail.cursorEl;
    var intersection = cursorEl.components.raycaster.getIntersection(
      this.laserHitPanelEl
    );
    if (!intersection) {
      return;
    }
    cursorEl.setAttribute("raycaster", "lineColor", "white");
    this.activeHandEl = cursorEl;
    this.oldHandX = undefined;
    this.oldHandY = undefined;
  },

  onMouseUpLaserHitPanel(evt) {
    var cursorEl = evt.detail.cursorEl;
    if (cursorEl === this.leftHandEl) {
      this.leftHandPressed = false;
    }
    if (cursorEl === this.rightHandEl) {
      this.rightHandPressed = false;
    }
    cursorEl.setAttribute("raycaster", "lineColor", "white");
    if (this.activeHandEl === cursorEl) {
      this.activeHandEl = undefined;
    }
  },

  onOrientationChange() {
    if (AFRAME.utils.device.isLandscape()) {
      this.cameraRigEl.object3D.position.z -= 1;
    } else {
      this.cameraRigEl.object3D.position.z += 1;
    }
  },

  onEnterVR() {
    var cameraRigEl = this.cameraRigEl;

    this.cameraRigPosition = cameraRigEl.object3D.position.clone();
    this.cameraRigRotation = cameraRigEl.object3D.rotation.clone();

    debugger;
    if (!this.el.sceneEl.is("ar-mode")) {
      cameraRigEl.object3D.position.set(0, 0, 2);
    } else {
      cameraRigEl.object3D.position.set(0, 0, 0);
    }
  },

  onExitVR() {
    var cameraRigEl = this.cameraRigEl;

    cameraRigEl.object3D.position.copy(this.cameraRigPosition);
    cameraRigEl.object3D.rotation.copy(this.cameraRigRotation);

    cameraRigEl.object3D.rotation.set(0, 0, 0);
  },

  onTouchMove(evt) {
    if (evt.touches.length === 1) {
      this.onSingleTouchMove(evt);
    }
    if (evt.touches.length === 2) {
      this.onPinchMove(evt);
    }
  },

  onSingleTouchMove(evt) {
    var dX;
    var dY;
    var modelPivotEl = this.modelPivotEl;
    this.oldClientX = this.oldClientX || evt.touches[0].clientX;
    this.oldClientY = this.oldClientY || evt.touches[0].clientY;

    dX = this.oldClientX - evt.touches[0].clientX;
    dY = this.oldClientY - evt.touches[0].clientY;

    modelPivotEl.object3D.rotation.y -= dX / 200;
    this.oldClientX = evt.touches[0].clientX;

    modelPivotEl.object3D.rotation.x -= dY / 100;

    // Clamp x rotation to [-90,90]
    modelPivotEl.object3D.rotation.x = Math.min(
      Math.max(-Math.PI / 2, modelPivotEl.object3D.rotation.x),
      Math.PI / 2
    );
    this.oldClientY = evt.touches[0].clientY;
  },

  onPinchMove(evt) {
    var dX = evt.touches[0].clientX - evt.touches[1].clientX;
    var dY = evt.touches[0].clientY - evt.touches[1].clientY;
    var modelPivotEl = this.modelPivotEl;
    var distance = Math.sqrt(dX * dX + dY * dY);
    var oldDistance = this.oldDistance || distance;
    var distanceDifference = oldDistance - distance;
    var modelScale = this.modelScale || modelPivotEl.object3D.scale.x;

    modelScale -= distanceDifference / 500;
    modelScale = Math.min(Math.max(1.0, modelScale), 4.5);
    // Clamp scale.
    modelPivotEl.object3D.scale.set(modelScale, modelScale, modelScale);

    this.modelScale = modelScale;
    this.oldDistance = distance;
  },

  onTouchEnd(evt) {
    this.oldClientX = undefined;
    this.oldClientY = undefined;
    if (evt.touches.length < 2) {
      this.oldDistance = undefined;
    }
  },

  onMouseUp(evt) {
    this.leftRightButtonPressed = false;
    if (evt.buttons === undefined || evt.buttons !== 0) {
      return;
    }
    this.oldClientX = undefined;
    this.oldClientY = undefined;
  },

  onMouseMove(evt) {
    if (this.leftRightButtonPressed) {
      this.dragModel(evt);
    } else {
      this.rotateModel(evt);
    }
  },

  onModelLoaded() {
    this.centerAndScaleModel();

    if (this.modelSedutaEl && !this.sedutaHasTexture) this.resetTextureSeduta();
    if (this.modelBaseEl) this.resetTextureBase();
    window.dispatchEvent(new Event("resize"));
  },

  onMouseDown(evt) {
    if (evt.buttons) {
      this.leftRightButtonPressed = evt.buttons === 3;
    }
    this.oldClientX = evt.clientX;
    this.oldClientY = evt.clientY;
  },
});
