import React, { useContext, useEffect } from "react";
import SidebarUi from "../ui/Sidebar/SidebarUi";
import ToggleUi from "../ui/Toggle/ToggleUi";
import IconUi from "../ui/Icon/IconUi";

import SidebarHeaderUi from "../ui/SidebarHeader/SidebarHeaderUi";
import SidebarStepsUi from "../ui/SidebarSteps/SidebarStepsUi";

import NavigationComponent from "./NavigationComponent";
import Step1Component from "./Step1Component";
import Step2Component from "./Step2Component";
import Step3Component from "./Step3Component";
import Step4Component from "./Step4Component";

import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import NavigationUi from "../ui/Navigation/NavigationUi";

export default function SidebarComponent() {
  const context = useContext(ConfiguratorContext);
  const onClickSetStep = (step) => {
    if (step && context.canGoTo(step - 1)) {
      context.setStep(step);
    }
  };
  useEffect(() => {
    if (context.sidebarOpen) {
      document.querySelector("aside").classList.add("is-visible");
    } else {
      document.querySelector("aside").classList.remove("is-visible");
    }
  }, [context.sidebarOpen]);

  const renderStep = () => {
    if (context.configuration.step === 1) return <Step1Component />;
    if (context.configuration.step === 2) return <Step2Component />;
    if (context.configuration.step === 3) return <Step3Component />;
    if (context.configuration.step === 4) return <Step4Component />;

    return null;
  };
  const buttonResetClass = context.sidebarOpen
    ? "button-reset is-hidden"
    : "button-reset";
  return (
    <>
      <div
        className={buttonResetClass}
        onClick={() => {
          context.resetData();
        }}
      >
        reset
        <IconUi icon="aggiorna"></IconUi>
      </div>
      <ToggleUi
        onClick={() => {
          context.setSidebarOpen(!context.sidebarOpen);
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 400);
        }}
        visible={context.sidebarOpen}
      />
      <SidebarUi visible={context.sidebarOpen}>
        <SidebarHeaderUi
          step={context.configuration.step}
          hideStep1={context.numberBasi < 2}
          hideStep3={context.state.productData.force_texture_to_base}
          onClickClose={() => {
            context.setSidebarOpen(!context.sidebarOpen);
            setTimeout(() => {
              window.dispatchEvent(new Event("resize"));
            }, 400);
          }}
          onClickStep={onClickSetStep}
          open={context.sidebarOpen}
        />

        <SidebarStepsUi title={context.state.productData?.label}>
          {renderStep()}
        </SidebarStepsUi>
        <NavigationUi>
          <NavigationComponent />
        </NavigationUi>
      </SidebarUi>
    </>
  );
}
