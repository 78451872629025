import React from "react";

export default function SidebarStepsUi(props) {
  const { children, title } = props;

  return (
    <div className="SidebarStepsUi">
      <h1>{title}</h1>
      {children}
    </div>
  );
}
