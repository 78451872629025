import React from "react"
import ReactDOM from "react-dom/client"

import AppSelect from "./indexFiltersSelect/App"
import AppText from "./indexFiltersText/App"

document.addEventListener("DOMContentLoaded", () => {
  const DOMContainer = document.getElementById("index-filters");
  if (!DOMContainer) return;

  const DOMSelects = document.querySelectorAll('.index__filters-select')
  DOMSelects.forEach((DOMSelect) => {
    const props = JSON.parse(DOMSelect.dataset.props)
    const Root = ReactDOM.createRoot(DOMSelect)

    Root.render(
      <React.StrictMode>
        <AppSelect { ...props } />
      </React.StrictMode>
    );
  })

  const DOMTexts = document.querySelectorAll('.index__filters-text')
  DOMTexts.forEach((DOMText) => {
    const props = JSON.parse(DOMText.dataset.props)
    const Root = ReactDOM.createRoot(DOMText)

    Root.render(
      <React.StrictMode>
        <AppText { ...props } />
      </React.StrictMode>
    );
  })
});
