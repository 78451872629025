import React from "react";
import PropTypes from "prop-types";
import IconUi from "../Icon/IconUi";

export default function SidebarHeaderUi(props) {
  const { onClickClose, step, hideStep1, hideStep3, onClickStep, open } = props;
  const iconButton = open ? (
    <IconUi icon="close" />
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 19.846">
      <g data-name="Raggruppa 513" transform="translate(-5950 5838)">
        <path
          data-name="Unione 1"
          d="M5978-5820.654h-28v-1h28Zm0-7h-28v-1h28Zm0-7h-28v-1h28Z"
          fill="#222221"
        />
        <path
          data-name="Tracciato 195"
          d="M5969.715-5838a3 3 0 1 1-3 3 3 3 0 0 1 3-3Z"
          fill="#fbf9f6"
        />
        <path
          data-name="Tracciato 195 - Contorno"
          d="M5969.715-5837a2 2 0 1 0 2 2 2 2 0 0 0-2-2m0-1a3 3 0 1 1-3 3 3 3 0 0 1 3-3Z"
          fill="#222221"
        />
        <circle
          data-name="Ellisse 35"
          cx="3"
          cy="3"
          r="3"
          transform="translate(5954 -5831.154)"
          fill="#fbf9f6"
        />
        <path
          data-name="Ellisse 35 - Contorno"
          d="M5957-5830.154a2 2 0 1 0 2 2 2 2 0 0 0-2-2m0-1a3 3 0 1 1-3 3 3 3 0 0 1 3-3Z"
          fill="#222221"
        />
        <circle
          data-name="Ellisse 36"
          cx="3"
          cy="3"
          r="3"
          transform="translate(5966 -5824.154)"
          fill="#fbf9f6"
        />
        <path
          data-name="Ellisse 36 - Contorno"
          d="M5969-5823.154a2 2 0 1 0 2 2 2 2 0 0 0-2-2m0-1a3 3 0 1 1-3 3 3 3 0 0 1 3-3Z"
          fill="#222221"
        />
      </g>
    </svg>
  );
  return (
    <div className="SidebarHeaderUi">
      {step === 4 ? (
        <p className="SidebarHeaderUi__title">
          {window.jsLocale("configurator_end_title")}
        </p>
      ) : (
        <ul className="SidebarHeaderUi__step">
          {!hideStep1 && (
            <li
              className={step === 1 ? "active" : ""}
              onClick={() => onClickStep(1)}
            >
              <div className="SidebarHeaderUi__step-dots"></div>
              {window.jsLocale("configurator_step")} 1
            </li>
          )}
          <li
            className={step === 2 ? "active" : ""}
            onClick={() => onClickStep(2)}
          >
            <div className="SidebarHeaderUi__step-dots"></div>
            {window.jsLocale("configurator_step")} {hideStep1 ? 1 : 2}
          </li>
          {!hideStep3 && (
            <li
              className={step === 3 ? "active" : ""}
              onClick={() => onClickStep(3)}
            >
              <div className="SidebarHeaderUi__step-dots"></div>
              {window.jsLocale("configurator_step")} {hideStep1 ? 2 : 3}
            </li>
          )}
        </ul>
      )}
      <span onClick={onClickClose} className="SidebarHeaderUi__close">
        {iconButton}
      </span>
    </div>
  );
}

SidebarHeaderUi.propTypes = {
  step: PropTypes.number.isRequired,
  hideStep1: PropTypes.bool,
  hideStep3: PropTypes.bool,
  open: PropTypes.bool,
};
SidebarHeaderUi.defaultProps = {
  step: 1,
};
