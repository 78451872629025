import React, { useState, useEffect } from "react"

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.matchMedia("only screen and (max-width: 960px)").matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia("only screen and (max-width: 960px)");
    const listener = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", listener);
    return () => mediaQuery.removeEventListener("change", listener);
  }, [])

  return isMobile;
}