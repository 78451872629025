import PropTypes from "prop-types";
import IconUi from "../Icon/IconUi";
import React, { useState, useEffect } from "react";

export default function SelectUi(props) {
  const { label, selected, children, open, setOpen, disabled } = props;
  const [selectOpen, setSelectOpen] = useState(open);

  const onCLick = () => {
    setSelectOpen(!selectOpen);
  };

  useEffect(() => {
    setSelectOpen(open);
  }, [open]);

  useEffect(() => {
    if (setOpen) setOpen(selectOpen);
  }, [selectOpen]);
  const mountedStyle = {
    animation: "inAnimation 250ms ease-in",
  };
  const unmountedStyle = {
    animation: "outAnimation 270ms ease-out",
    animationFillMode: "forwards",
  };
  const cursor = {
    pointerEvents: disabled ? "none" : "auto",
    opacity: disabled ? ".5" : "1",
  };
  const classSelecUi = selectOpen ? "is-open SelectUi" : "SelectUi";

  return (
    <div className={classSelecUi} style={cursor}>
      <div
        className={["box-label", selectOpen ? "is-open" : ""].join(" ")}
        onClick={onCLick}
      >
        <span>
          {label}
          {selected ? ": " : ""}
          <span className="box-label__selected">{selected}</span>
        </span>
        <div className={selected && !open ? "button-edit" : "button-action"}>
          {selected && !open ? <span>Modifica</span> : ""}
          <IconUi
            icon={
              !selectOpen && !selected
                ? "plus"
                : selected && !open
                ? "edit"
                : "minus-2"
            }
          />
        </div>
      </div>
      {selectOpen ? (
        <div style={selectOpen ? mountedStyle : unmountedStyle}>{children}</div>
      ) : (
        ""
      )}
    </div>
  );
}

SelectUi.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  disabled: PropTypes.bool,
};

SelectUi.defaultProps = {
  disabled: false,
};
