import PropTypes from "prop-types";
import React, { useState } from "react";
import IconUi from "../Icon/IconUi";

export default function InputUi(props) {
  const { label, onChange, value } = props;
  const [focus, setFocus] = useState(false);
  const classFocus = focus ? "is-focus InputUi" : "InputUi";

  return (
    <div className={classFocus}>
      <div className="InputUi__search">
        <span>{label}</span>
        <input
          type="text"
          value={value}
          onChange={onChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </div>
      <div className="InputUi__icon">
        <IconUi icon="search" />
      </div>
    </div>
  );
}

InputUi.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};
