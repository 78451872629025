import React, { useContext } from "react";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import IconUi from "../ui/Icon/IconUi";
import useIsMobile from "../hooks/useIsMobile";

export default function NavigationComponent() {
  const isMobile = useIsMobile();
  const context = useContext(ConfiguratorContext);

  const onClickNext = () => {
    context.goToNext();
    if (isMobile && context.sidebarOpen != true) {
      context.setSidebarOpen(true);
    }
  };

  const onClickNextConfig = () => {
    if (context.sidebarOpen != true) {
      context.setSidebarOpen(true);
      context.setCurrentSelect(context.oldCurrentSelect + 1);
    }
  };

  const onClickPrint = () => {
    const printOverlay = document.querySelector(".print-overlay");
    const aframeScene = document.querySelector("a-scene");

    const screenWidth = 375 * 3;
    const screenHeight = 600 * 3;

    // show print overlay
    printOverlay.classList.add("is-active");

    // set custom size to export image
    aframeScene.style.width = `${screenWidth}px`;
    aframeScene.style.height = `${screenHeight}px`;
    window.dispatchEvent(new Event("resize"));

    // remove background from aframe scene using method hideBackground() of model-viewer component applied to a-scene
    aframeScene.components["model-viewer"].hideBackground();

    // put image inside print-container__image
    const printContainerImage = document.querySelector(
      ".print-container__image"
    );
    aframeScene.setAttribute(
      "screenshot",
      `width: ${screenWidth}; height: ${screenHeight}; fileFormat: jpg;`
    );
    const imageUrl = aframeScene.components.screenshot
      .getCanvas("perspective")
      .toDataURL("image/jpg");
    printContainerImage.innerHTML = "";
    const printContainerImageImg = document.createElement("img");
    printContainerImageImg.src = imageUrl;
    printContainerImage.appendChild(printContainerImageImg);

    // reset background with method showBackground() of model-viewer component applied to a-scene
    aframeScene.components["model-viewer"].showBackground();

    // reset size to default
    aframeScene.style.width = "";
    aframeScene.style.height = "";
    window.dispatchEvent(new Event("resize"));

    setTimeout(() => {
      window.print();

      setTimeout(() => {
        printOverlay.classList.remove("is-active");
      }, 1000);
    }, 500);
  };

  const onClickPrev = () => {
    context.goToPrev();
  };
  let backView = false;
  backView =
    (context.configuration.step > 1 && context.numberBasi > 1) ||
    (context.configuration.step > 2 && context.numberBasi === 1);

  console.log('NavigationComponent')
  console.log('step', context.configuration.step)
  console.log('canGoToNext', context.canGoToNext)
  console.log('sidebarOpen', context.sidebarOpen)

  return (
    <>
      {backView && (
        <button className="button-nav button-nav--back" onClick={onClickPrev}>
          <IconUi icon="back"></IconUi>
          {window.jsLocale("cta_back")}
        </button>
      )}

      {context.canGoToNext && context.configuration.step != 4 ? (
        <button
          className="button-nav button-nav--next"
          onClick={onClickNext}
          disabled={!context.canGoToNext}
        >
          {window.jsLocale("cta_next")} <IconUi icon="next"></IconUi>
        </button>
      ) : (
        ""
      )}
      {context.configuration.step === 2 &&
      context.sidebarOpen != true &&
      !context.canGoToNext ? (
        <button
          className="button-nav button-nav--next-config"
          onClick={onClickNextConfig}
        >
          {window.jsLocale("cta_next")} <IconUi icon="next"></IconUi>
        </button>
      ) : (
        ""
      )}
      {context.configuration.step === 4 ? (
        <button
          className="button-nav button-nav--pdf"
          onClick={onClickPrint}
          disabled={!context.canGoToNext}
        >
          {window.jsLocale("cta_export_pdf")} <IconUi icon="download"></IconUi>
        </button>
      ) : (
        ""
      )}
    </>
  );
}
