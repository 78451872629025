import React, { useState, useMemo, useCallback } from "react";
import InputUi from "../ui/Input/InputUi";

export default function App(props) {
  const { name, label } = props;
  const [value, setValue] = useState("");

  const onChange = useCallback(
    (e) => {
      const { value } = e.target;
      setValue(value);

      const input = document.querySelector(`input[name="${name}"]`);
      if (input) {
        input.value = value;
        input.dispatchEvent(new Event("keyup"));
      }
    },
    [open]
  );

  return <InputUi value={value} label={`${label}:`} onChange={onChange} />;
}
