import React from "react";
import ReactDOM from "react-dom/client";
// import { disableBodyScroll } from "body-scroll-lock";

import { ConfiguratorProvider } from "./contexts/ConfiguratorContext";
import App from "./configurator/App";

document.addEventListener("DOMContentLoaded", () => {
  const DOMContainer = document.getElementById("configurator");
  if (!DOMContainer) return;

  const DOMAside = DOMContainer.querySelector("aside");
  // const DOMMain = DOMContainer.querySelector("main");
  // disableBodyScroll(DOMMain);

  const Root = ReactDOM.createRoot(DOMAside);
  Root.render(
    <React.StrictMode>
      <ConfiguratorProvider product={DOMContainer.dataset.product} clientUnitSize={DOMContainer.dataset.clientunitsize}>
        <App />
      </ConfiguratorProvider>
    </React.StrictMode>
  );
});
