import React, { useContext, useMemo, useState } from "react";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import SelectUi from "../ui/Select/SelectUi";
import SelectListUi from "../ui/SelectList/SelectListUi";
import useIsMobile from "../hooks/useIsMobile";

export default function Step3Component() {
  const isMobile = useIsMobile();
  const context = useContext(ConfiguratorContext);

  const materiali = useMemo(() => {
    if (!context.selectedBase) return null;

    return context.selectedBase.textures.filter((t) => t.typology == "general");
  }, [context.selectedBase]);

  const materialiGas = useMemo(() => {
    if (!context.selectedMaterialeBase) return null;
    if (context.selectedMaterialeBase.force_on_gas) return null;

    // Negli sgabelli, solo nelle basi 50, 51, 52, 53, 54, 55 e 57 si fa eccezione:
    // I materiali VD, DS e DO avranno SEMPRE le seguenti forzature sui gas:
    // VD --> DS
    // DS --> DS
    // DO --> DO
    if(context.state.productData.typology == 'sgabello') {
      if(context.selectedBase.id == "BASE 50" || context.selectedBase.id == "BASE 51" || context.selectedBase.id == "BASE 52" || context.selectedBase.id == "BASE 53" || context.selectedBase.id == "BASE 54" || context.selectedBase.id == "BASE 55" || context.selectedBase.id == "BASE 57" ) {
        if(context.selectedMaterialeBase.id == 'VD' || context.selectedMaterialeBase.id == 'DS' || context.selectedMaterialeBase.id == 'DO') {
          return null
        }
      }
    }
    const gasMaterials = context.selectedBase.textures.filter(
      (t) => t.typology == "gas"
    );

    // DS è presente solo per la forzatura nel caso venga scelto VD, quindi va rimosso
    // dalla lista dei gas disponibili
    const ds = context.selectedBase.textures.find(
      (t) => t.id == "DS" && t.typology == "gas" && t.force_on_gas == true
    )
    console.log(ds)
    if(ds) gasMaterials.splice(gasMaterials.indexOf(ds), 1)


    return gasMaterials.length > 0 ? gasMaterials : null;
  }, [context.selectedBase, context.selectedMaterialeBase]);

  const onClickFinituraBase = (materiale) => {
    const newData = { textureBase: materiale };

    // Negli sgabelli, solo nelle basi 50, 51, 52, 53, 54, 55 e 57 si fa eccezione:
    // I materiali VD, DS e DO avranno SEMPRE le seguenti forzature sui gas:
    // VD --> DS
    // DS --> DS
    // DO --> DO
    if (materiale.force_on_gas) {
      if(context.state.productData.typology == 'sgabello') {
        if(context.selectedBase.id == "BASE 50" || context.selectedBase.id == "BASE 51" || context.selectedBase.id == "BASE 52" || context.selectedBase.id == "BASE 53" || context.selectedBase.id == "BASE 54" || context.selectedBase.id == "BASE 55" || context.selectedBase.id == "BASE 57" ) {
          if(materiale.id == 'VD') {
            newData.textureBaseGas = context.selectedBase.textures.find(
              (t) => t.id == "DS" && t.typology == "gas"
              )
          } else {
            newData.textureBaseGas = materiale;
          }
        } else {
          newData.textureBaseGas = materiale;
        }
      } else {
        newData.textureBaseGas = materiale;
      }
    } else {
      let gasMaterials = []
      if(context.state.productData.typology == 'sgabello') {
        if(context.selectedBase.id == "BASE 50" || context.selectedBase.id == "BASE 51" || context.selectedBase.id == "BASE 52" || context.selectedBase.id == "BASE 53" || context.selectedBase.id == "BASE 54" || context.selectedBase.id == "BASE 55" || context.selectedBase.id == "BASE 57" ) {
          if(materiale.id == 'VD') {
            newData.textureBaseGas = context.selectedBase.textures.find(
              (t) => t.id == "DS" && t.typology == "gas"
            )
          } else if (materiale.id == 'DS' || materiale.id == 'DO') {
            newData.textureBaseGas = materiale
          } else {
            gasMaterials = context.selectedBase.textures.filter(
              (t) => t.typology == "gas"
              );
              if (gasMaterials.length > 0) {
                newData.textureBaseGas = gasMaterials[0];
              } else {
                newData.textureBaseGas = null;
              }
            }
        } else {
            gasMaterials = context.selectedBase.textures.filter(
              (t) => t.typology == "gas"
              );
              if (gasMaterials.length > 0) {
                newData.textureBaseGas = gasMaterials[0];
              } else {
                newData.textureBaseGas = null;
              }
        }     
        
      } else {
        gasMaterials = context.selectedBase.textures.filter(
          (t) => t.typology == "gas"
        );
        if (gasMaterials.length > 0) {
          newData.textureBaseGas = gasMaterials[0];
        } else {
          newData.textureBaseGas = null;
        }
      }
    }

    context.setData(newData);
    setCurrentSelect(0);
    if (isMobile) {
      context.setSidebarOpen(!context.sidebarOpen);
    }
  };

  const onClickFinituraBaseGas = (materiale) => {
    context.setData({ textureBaseGas: materiale });
    setCurrentSelect(0);
    if (isMobile) {
      context.setSidebarOpen(!context.sidebarOpen);
    }
  };

  const [currentSelect, setCurrentSelect] = useState(1);

  const baseHasOptions = useMemo(() => {
    if (!context.selectedBase) return false;

    return (
      context.selectedBase.option_memory_return ||
      context.selectedBase.option_gas_lift
    );
  }, [context.selectedBase]);

  return (
    <div>
      {materiali && (
        <>
          <p className="SidebarStepsUi__label">
            {window.jsLocale("configurator_s3_title")}
          </p>
          <SelectUi
            label={window.jsLocale("configurator_base_material")}
            selected={context.selectedMaterialeBase?.label}
            open={currentSelect == 1}
            setOpen={(open) =>
              setCurrentSelect(
                open ? 1 : currentSelect == 1 ? 0 : currentSelect
              )
            }
          >
            <SelectListUi
              options={materiali}
              activeId={context.selectedMaterialeBase?.id}
              onClick={onClickFinituraBase}
              withImage
              withCode
            />
          </SelectUi>

          {materialiGas && (
            <>
              <SelectUi
                label={window.jsLocale("configurator_base_material_gas")}
                selected={context.selectedMaterialeBaseGas?.label}
                open={currentSelect == 2}
                setOpen={(open) =>
                  setCurrentSelect(
                    open ? 2 : currentSelect == 2 ? 0 : currentSelect
                  )
                }
              >
                <SelectListUi
                  options={materialiGas}
                  activeId={context.selectedMaterialeBaseGas?.id}
                  onClick={onClickFinituraBaseGas}
                  withImage
                  withCode
                />
              </SelectUi>
            </>
          )}

          {/** Visualizzazione opzioni (checkbox) legate alla base selzionata  */}
          {baseHasOptions && (
            <>
              {context.selectedBase.option_memory_return && (
                  <SelectUi
                    label="Memory Return"
                    selected={
                      context.configuration?.data?.optionBase_memory_return
                        ? window.jsLocale("cta_yes").toUpperCase()
                        : window.jsLocale("cta_no").toUpperCase()
                    }
                    open={context.currentSelect === 6}
                    setOpen={(open) =>
                      context.setCurrentSelect(open ? 6 : context.currentSelect === 6 ? 0 : context.currentSelect)
                    }
                  >
                    <SelectListUi
                      options={[
                        { id: 1, label: window.jsLocale("cta_yes").toUpperCase(), value: true },
                        { id: 2, label: window.jsLocale("cta_no").toUpperCase(), value: false }
                      ]}
                      activeId={
                        context.configuration?.data?.optionBase_memory_return ? 1 : 2
                      }
                      onClick={(option) => {
                        if (option.value && !!context.configuration?.data?.optionBase_gas_lift) {
                          return;
                        }
                        context.setData({
                          optionBase_memory_return: option.value,
                          optionBase_gas_lift: !option.value ? context.configuration?.data?.optionBase_gas_lift : false
                        });
                        context.setCurrentSelect(0);
                      }}
                    />
                  </SelectUi>
              )}
              {context.selectedBase.option_gas_lift && (
                <SelectUi
                  label="Gas Lift"
                  selected={
                    context.configuration?.data?.optionBase_gas_lift
                      ? window.jsLocale("cta_yes").toUpperCase()
                      : window.jsLocale("cta_no").toUpperCase()
                  }
                  open={context.currentSelect === 7}
                  setOpen={(open) =>
                    context.setCurrentSelect(open ? 7 : context.currentSelect === 7 ? 0 : context.currentSelect)
                  }
                >
                  <SelectListUi
                    options={[
                      { id: 1, label: window.jsLocale("cta_yes").toUpperCase(), value: true },
                      { id: 2, label: window.jsLocale("cta_no").toUpperCase(), value: false }
                    ]}
                    activeId={
                      context.configuration?.data?.optionBase_gas_lift ? 1 : 2
                    }
                    onClick={(option) => {
                      if (option.value && !!context.configuration?.data?.optionBase_memory_return) {
                        return;
                      }
                      context.setData({
                        optionBase_gas_lift: option.value,
                        optionBase_memory_return: !option.value ? context.configuration?.data?.optionBase_memory_return : false
                      });
                      context.setCurrentSelect(0);
                    }}
                  />
                </SelectUi>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
