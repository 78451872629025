import React from "react";
import IconUi from "../Icon/IconUi";
import PropTypes from "prop-types";

export default function ListItemUi(props) {
  const { label, value, onClick } = props;

  return (
    <li className="ListItemUi" onClick={onClick}>
      <div className="ListItemUi__text">
        <span>{label}</span>
        <p>{value}</p>
      </div>
      {onClick && (
        <div className="ListItemUi__icon">
          <div className="button-edit">
            <span>Modifica</span>
            <IconUi icon="edit"></IconUi>
          </div>
        </div>
      )}
    </li>
  );
}

ListItemUi.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
