
import React, { useContext } from "react"
import { ConfiguratorContext } from "../contexts/ConfiguratorContext"
import SidebarComponent from "./SidebarComponent"
import LoadingUi from '../ui/Loading/LoadingUi'

export default function App() {
  const context = useContext(ConfiguratorContext)

  if (!context.state.loaded) {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LoadingUi />
      </div>
    )
  }

  if (context.state.loaded && context.state.loadedError) {
    return null; // TO-DO: Messaggio errore
  }

  return <SidebarComponent />
}
