import PropTypes from "prop-types";
import React from "react";

export default function SelectListUi(props) {
  const { options, activeId, onClick, withImage, withCat, withCode, hideCurrent } = props;

  return (
    <ul className="SelectUi__list">
      {options
        .filter((option) => !hideCurrent || option.id != activeId)
        .map((option) => (
          <li
            key={option.id}
            className={[
              "SelectUi__item",
              activeId == option.id ? "is-active" : "",
            ].join(" ")}
            onClick={(e) => {
              // console.log("activeId", activeId);
              // console.log("option.id", option.id);

              onClick(option);
            }}
          >
            {withImage && (
              <div
                className="SelectUi__list-thumb"
                style={{ backgroundImage: `url(${option.image})` }}
              ></div>
            )}
            {withCat || withCode ? (
              <div className="SelectUi__list-text">
                <span>{option.label}</span>
                {withCat && option.material_typology && option.material_typology != "general" && (
                  <span className="SelectUi__list-cat">
                    ({window.jsLocale(`enum_material_typology_${option.material_typology}`)})
                  </span>
                )}
                {withCode && option.id && (
                  <span className="SelectUi__list-cat">
                  {option.id}
                </span>)}
              </div>
            ) : (
              <span>{option.label}</span>
            )}
          </li>
        ))}
    </ul>
  );
}

SelectListUi.propTypes = {
  onClick: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  withImage: PropTypes.bool,
  withCode: PropTypes.bool,
  withCat: PropTypes.bool,
  activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
