import React, { useState, useMemo, useCallback } from "react";
import SelectUi from "../ui/Select/SelectUi";
import SelectListUi from "../ui/SelectList/SelectListUi";

export default function App(props) {
  const { name, label, options } = props;
  const [selected, setSelected] = useState(options[0].value);
  const [open, setOpen] = useState(false);

  const selectedLabel = useMemo(() => {
    const option = options.find((o) => o.value == selected);
    return option?.label;
  }, [selected]);

  const onSelect = useCallback(
    (option) => {
      const { value } = option;
      setSelected(value);
      setOpen(false);

      const input = document.querySelector(`input[name="${name}"]`);
      if (input) {
        input.value = value;
        input.dispatchEvent(new Event("change"));
      }
    },
    [open]
  );

  return (
    <SelectUi
      label={label}
      selected={selectedLabel}
      open={open}
      setOpen={setOpen}
    >
      <SelectListUi
        options={options}
        activeId={selected}
        onClick={onSelect}
        hideCurrent={true}
      />
    </SelectUi>
  );
}
