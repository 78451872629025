import PropTypes from "prop-types";
import React from "react";

export default function SidebarColorsUi(props) {
  const {
    onClick,
    onMouseEnter,
    onMouseLeave,
    colors,
    selected,
    activeColorId,
  } = props;
  return (
    <ul className="SidebarColorsUi">
      {colors.map((color) => (
        <li
          key={color.id}
          onClick={() => onClick(color)}
          onMouseEnter={() => onMouseEnter(color)}
          onMouseLeave={() => onMouseLeave(selected)}
          className={[
            "SidebarColorsUi__color",
            activeColorId == color.id ? "is-active" : "",
          ].join(" ")}
        >
          <div
            className="SidebarColorsUi__image"
            style={{
              backgroundColor: color.color,
              backgroundImage: `url(${color.color_texture_preview})`,
            }}
          ></div>
          <div className="SidebarColorsUi__label">
            <div className="selector"></div> {color.id}
          </div>
        </li>
      ))}
    </ul>
  );
}

SidebarColorsUi.propTypes = {
  colors: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  previewMaterial: PropTypes.string,
  activeColorId: PropTypes.string,
};
